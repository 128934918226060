import axios from 'axios'
const state = {
  sidebar: {
    opened: false,
  },
  config: {
    palette: {
      primary: '#4ae387',
      danger: '#e34a4a',
      info: '#4ab2e3',
      success: '#db76df',
      warning: '#f7cc36',
      white: '#fff',
      black: '#000',
      fontColor: '#34495e',
      transparent: 'transparent',
      lighterGray: '#ddd',
    },
  },
  user_status: '1',
  levels: [],
  isLoading: true,
  todos: [],
  logger: [],
  header: '',
}

const mutations = {
  setLoading (state, isLoading) {
    state.isLoading = isLoading
  },
  updateUserStatus (state, response) {
    state.user_status = response
  },
  updateLevel (state, response) {
    state.levels = response
  },
  updateLogger (state, data) {
    state.logger = data
  },
  updateHeader (state, data) {
    state.header = data
  },
  setTodos: (state, todos) => state.todos = todos,
}

const actions = {
  token ({ commit }) {
    let data = ''
    if (localStorage.getItem('logged_user')) {
      data = this.logger = JSON.parse(localStorage.getItem('logged_user'))
      commit('updateLogger', data)
    }
    commit('updateHeader', data.accessToken)
  },
  fetchLevel ({ commit }) {
    this.loading = true
    if (localStorage.getItem('logged_user')) {
      this.logger = JSON.parse(localStorage.getItem('logged_user'))
    }
    this.header = this.logger.accessToken
    this.axios({
      url: 'get_parent_info',
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + this.header,
      },
    })
      .then((response) => {
        // Then injecting the result to datatable parameters.
        commit('updateLevel', response.data)
        return response.data
      })
  },
  async fetchTodos ({ commit }) {
    // const response = await axios.get('http://dev.Qollege.rw/get_selected_intake')
    if (localStorage.getItem('logged_user')) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
    this.header = this.logger.accessToken
    const response = await axios({
      url: 'http://dev.Qollege.rw/get_selected_mentors',
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + state.header,
      },
    })
    commit('setTodos', response.data)
  },
  commitUpdateUserStatus ({ commit }, data) {
    commit('updateUserStatus', data)
  },
}
const getters = {
  levels: (state) => state.levels,
  allTodos: (state) => state.todos,
}

export default {
  state,
  mutations,
  actions,
  getters,
}

import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '../components/auth/AuthLayout'
import AppLayout from '../components/admin/AppLayout'

Vue.use(Router)

const EmptyParentComponent = {
  template: '<router-view></router-view>',
}

const demoRoutes = []

export default new Router({
  // mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    ...demoRoutes,
    // {
    //   path: '/',
    //   redirect: { name: 'register' },
    // },
    {
      name: 'register',
      path: '/',
      component: () => import('../components/pages/Register.vue'),
      // default: true,
    },
    {
      name: 'login-page',
      path: '/login',
      component: () => import('../components/pages/Login.vue'),
      default: true,
    },
    {
      name: 'Admin',
      path: '/admin',
      component: AppLayout,
      children: [
        {
          name: 'dashboard',
          path: 'dashboard',
          component: () => import('../components/dashboard/Dashboard.vue'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'registration',
          path: 'registration-process',
          component: () => import('../components/pages/Registration/registrationForm'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'students',
          path: 'students-registered',
          component: () => import('../components/pages/view-students'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'intakes',
          path: 'available-intakes',
          component: () => import('../components/pages/Intakes/Intakes'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'faculty',
          path: 'faculty',
          component: () => import('../components/pages/Faculty/Faculty'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'department',
          path: 'department',
          component: () => import('../components/pages/Departments/Department'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'class',
          path: 'class',
          component: () => import('../components/pages/Classes/Class'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'communication',
          path: 'communication-portal',
          component: () => import('../components/pages/Communication/Communication'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'courses',
          path: 'course',
          component: () => import('../components/pages/Courses/Courses'),
          meta: { requiresAuth: true },
        },
        {
          name: 'course-records',
          path: 'course-records',
          component: () => import('../components/pages/Courses/course-records'),
          meta: { requiresAuth: true },
        },
        {
          name: 'settings',
          path: 'school-settings',
          component: () => import('../components/pages/Settings'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'staff',
          path: 'staff',
          component: () => import('../components/pages/Staff/Staff'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'levels',
          path: 'levels',
          component: () => import('../components/pages/levels/Levels'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          name: 'marks',
          path: 'marks-entry',
          component: () => import('../components/pages/Marks/marks'),
          default: true,
          meta: { requiresAuth: true },
        },
        {
          path: '/students/:class_id',
          name: 'student',
          component: () => import('../components/pages/student-in-class'),
          meta: { requiresAuth: true },
        },
      ],
    },
  ],
})

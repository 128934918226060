<template>
  <div>
    <va-dropdown
      class="profile-dropdown"
      v-model="isShown"
      boundary-body
      offset="0, 16px"
    >
      <span
        class="profile-dropdown__anchor" slot="anchor"
        :style="{color: this.$themes.primary}"
      >
        <slot/>
        <va-icon
          class="pa-1"
          :name="`fa ${isShown ? 'fa-angle-up' :'fa-angle-down'}`"
        />
      </span>
      <div class="profile-dropdown__content pl-4 pr-4 pt-2 pb-2">
        <span
          @click="logout"
          class="profile-dropdown__item pt-1 pb-1 mt-2 mb-2"
        >
          Logout
        </span>
        <span v-if="showStatus" style="white-space: nowrap">{{status}} <i class="fa fa-check" aria-hidden="true" v-if="approvedIcon" style="color: #32a856;"></i>
          <i class="fa fa-check" aria-hidden="true" v-if="rejectedIcon" style="color: #a83232;"></i>
        </span>
        <span
          @click="settings"
          class="profile-dropdown__item pt-1 pb-1 mt-2 mb-2"
          v-if="showSettings"
        >Settings
        </span>
      </div>
    </va-dropdown>
  </div>
</template>

<script>
export default {
  name: 'profile-section',
  data () {
    return {
      isShown: false,
      visible: true,
      students: [],
      admission: true,
      show: true,
      response: '',
      showSettings: true,
      status: 'status',
      showStatus: true,
      approvedIcon: false,
      rejectedIcon: false,
    }
  },
  props: {
    options: {
      type: Array,
      default: () => [
        {
          name: 'profile',
          redirectTo: '',
        },
        {
          name: 'logout',
          redirectTo: 'login-page',
        },
        {
          name: 'Pending',
          redirectTo: '',
        },
      ],
    },
  },
  created () {
    this.fetchStudents()
  },
  methods: {
    logout () {
      localStorage.clear()
      this.$router.push({ name: 'login-page' })
      this.visible = false
    },
    settings () {
      this.$router.push({ name: 'settings' })
      this.visible = false
    },
    async fetchStudents () {
      const user = localStorage.getItem('logged_user')
      const dash = JSON.parse(user)
      if (user) { this.logger = JSON.parse(localStorage.getItem('logged_user')) }
      this.header = this.logger.accessToken
      try {
        const res = await this.axios({
          url: 'get_students_status/' + dash.uid,
          method: 'get',
          headers: {
            Authorization: 'Bearer ' + this.header,
          },
        })
        if (res.status === 200) {
          if (res.data.status === '4' && res.data.type === '1') {
            this.status = 'No registration'
          } else if (res.data.status === '1' && res.data.type === '1') {
            this.status = 'Pending...'
          } else if (res.data.status === '2' && res.data.type === '1') {
            this.approvedIcon = true
            this.status = 'Approved'
          } else if (res.data.status === '3' && res.data.type === '1') {
            this.rejectedIcon = true
            this.status = 'Rejected'
          }
          this.$emit('recieveEvent', res.data)
          // console.log('status:' + res.data.status + 'type:' + res.data.type)
          this.students = res.data
          console.log(res.data)
          if (res.data.type === '1') {
            this.showSettings = false
          } else if (res.data.type === '1' || res.data.status !== '2') {
            this.showStatus = false
            this.show = true
          }
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login-page' })
        } else {
          console.log(error)
        }
      }
    },
  },
}
</script>

<style lang="scss">

  .profile-dropdown {
    cursor: pointer;

    &__anchor {
      color: $vue-green;
    }

    .va-dropdown-popper__anchor {
      display: flex;
      justify-content: flex-end;
    }

    &__content {
      background-color: $dropdown-background;
      box-shadow: $gray-box-shadow;
      border-radius: 0.5rem;
      width: 8rem;
    }

    &__item {
      display: block;
      color: $vue-darkest-blue;

      &:hover,
      &:active {
        color: $vue-green;
      }
    }

    .va-dropdown__anchor {
      display: inline-block;
    }
    .profile-dropdown__content {
      padding-left: 5px !important;
    }
  }
</style>
